import { Color } from "../domains/common/@types/color";

export const colors: Color[] = [
    "red",
    "cyan",
    "green",
    "orange",
    "pink",
    "purple",
    "teal",
    "yellow",
];
