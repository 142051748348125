export const colors = {
    brand: {
        default: "#822838",
        light: "#a33346",
        dark: "#7a2634",
        darkest: "#4a171f",
        900: "var(--chakra-colors-brand-default)",
        800: "var(--chakra-colors-brand-default)",
        700: "var(--chakra-colors-brand-default)",
        600: "var(--chakra-colors-brand-default)",
        500: "var(--chakra-colors-brand-default)",
        400: "var(--chakra-colors-brand-default)",
        300: "var(--chakra-colors-brand-default)",
        200: "var(--chakra-colors-brand-default)",
        100: "var(--chakra-colors-brand-default)",
        50: "var(--chakra-colors-brand-default)",
    },
    primary: {
        default: "#2064f5",
        light: "#19abff",
        dark: "#242f46",
        darkest: "#161d2b",
        900: "var(--chakra-colors-primary-default)",
        800: "var(--chakra-colors-primary-default)",
        700: "var(--chakra-colors-primary-default)",
        600: "var(--chakra-colors-primary-default)",
        500: "var(--chakra-colors-primary-default)",
        400: "var(--chakra-colors-primary-default)",
        300: "var(--chakra-colors-primary-default)",
        200: "var(--chakra-colors-primary-default)",
        100: "var(--chakra-colors-primary-default)",
        50: "var(--chakra-colors-primary-default)",
    },
    gray: {
        900: "#141414",
        800: "#191919",
        700: "#1e1e1e",
        600: "#232323",
        500: "#282828",
        400: "#2d2d2d",
        300: "#323232",
        200: "#373737",
        100: "#3c3c3c",
    },
    danger: "var(--chakra-colors-red-600)",
    success: "var(--chakra-colors-green-600)",
    textMuted: "var(--chakra-colors-whiteAlpha-500)",
};
